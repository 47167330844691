import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import './Accesibility.css'




const Accessibility = () => {


	return (
		<>
			<Navbar />
			<section>

				<div className="mt-16 mb-16 px-8 ">
					<div className="container mx-auto w-[95%] h-[20%]">
						<div className="relative pt-10 text-center font-Montserrat font-black">
							<h2 className="block text-3xl lg:text-4xl text-[#5c5959]"> Accesibilidad</h2>
						</div>
						<div className="py-12 flex justify-center border border-white bg-white ">
							<div className="w-3/4 text-xs justify-center  items-center  ">
								<div className="flex justify-start items-start flex-col text-[#5c5959]">
									<h2 className="titulosAccesibility">Declaración De Accesibilidad</h2>
									<p className="underline-offset-2">Chako Grill se ha comprometido a hacer accesible su sitio web, de conformidad con el
										<a className=".enlacesAccesibility text-chako-700"
											href="https://www.boe.es/diario_boe/txt.php?id=BOE-A-2018-12699"> Real Decreto 1112/2018, de 7 de septiembre, sobre accesibilidad de los sitios web y aplicaciones para dispositivos móviles del sector público.
											<br />
											<br />
										</a> La presente declaración de accesibilidad se aplica al sitio web: <a className=".enlacesAccesibility text-chako-700" href="https://www.chako.es/">https://www.chako.es/ </a>
									</p>

									<h2 className="titulosAccesibility">SITUACIÓN DE CUMPLIMIENTO</h2>
									<p className="underline-offset-2">
										Este sitio web es parcialmente conforme con el <a className=".enlacesAccesibility text-chako-700" href="http://www.boe.es/diario_boe/txt.php?id=BOE-A-2018-12699">RD 1112/2018</a> debido a las excepciones y a la falta de conformidad de los aspectos que se indican en el siguiente punto.
									</p>

									<h2 className="titulosAccesibility">CONTENIDO NO ACCESIBLE</h2>
									<p className="underline-offset-2">El contenido que se recoge a continuación no es accesible por lo siguiente:</p>
									<br />
									<li >Falta de conformidad con el RD 1112/2018</li>
									<br />
									<p className="underline-offset-2">Podrían existir algunos aspectos de contraste que no cumplan los mínimos exigidos, 3:1, con respecto a los colores adyacentes del fondo de la sección de la web en cuestión. [9.1.4.11 Contraste no textual]</p>
									<br />
									<p className="underline-offset-2">Podrían existir algunos elementos no textuales no etiquetados correctamente [2.5.3 Etiqueta en el nombre] y [4.1.2 Nombre función y valor]</p>
									<br />
									<p className="underline-offset-2">Los buscadores buscan automáticamente sin necesidad de ordenar ninguna acción por parte del usuario [3.2.2 Al recibir entradas]</p>
									<br />
									<p className="underline-offset-2">Podrían existir algunos textos en otro idioma no etiquetados correctamente [3.1.2 Idioma de las partes]</p>
									<br />
									<p className="underline-offset-2">Podrían existir errores de código puntuales de edición en alguna página web. [4.1.1 Procesamiento]</p>
									<br />
									<li>Carga desproporcionada</li>
									<br />
									<p className="underline-offset-2">No aplica.</p>
									<br />
									<li>El contenido no entra dentro del ámbito de la legislación aplicable</li>
									<br />
									<p className="underline-offset-2">Podrían existir archivos ofimáticos en PDF u otros formatos publicados antes del 20 de septiembre de 2018 que no cumplan en su totalidad todos los requisitos de accesibilidad. Aunque se ha procurado que la mayoría de ellos sí lo cumplan.</p>

									<h2 className="titulosAccesibility">PREPARACIÓN DE LA SIGUIENTE DECLARACIÓN DE ACCESIBILIDAD</h2>

									<p className="underline-offset-2">La presente declaración fue preparada el 16 de diciembre de 2019.
										<br />
										<br />
										El método empleado para preparar la declaración ha sido una autoevaluación llevada a cabo por el propio organismo.
										<br />
										<br />
										La Declaración de Accesibilidad ha sido revisada y actualizada en julio de 2022.</p>
									<h2 className="titulosAccesibility">OBSERVACIONES Y DATOS DE CONTACTO</h2>
									<p className="underline-offset-2">Puede realizar comunicaciones sobre requisitos de accesibilidad (articulo 10.2.a del RD 1112/2018) como por ejemplo:</p>
									<br />
									<br />
									<li >Informar sobre cualquier posible incumplimiento por parte de este sitio web</li>
									<li >Transmitir otras dificultades de acceso al contenido</li>
									<li >Formular cualquier otra consulta o sugerencia de mejora relativa a la accesibilidad del sitio web</li>
									<br />
									<br />
									<p className="underline-offset-2">A través del número de teléfono 654 45 60 25.
										<br />
										<br />
										Puede presentar:
									</p>
									<br />
									<li ><span className="font-bold">Queja</span> relativa al cumplimiento de los requisitos del RD 1112/2018 o</li>
									<li ><span className="font-bold">Solicitud de Información accesible</span>  relativa a:</li>
									<br />
									<p className="underline-offset-2">
										Contenidos que están excluidos del ámbito de aplicación del RD 1112/2018 según lo establecido por el artículo 3, apartado 4
										<br />
										<br />
										Contenidos que están exentos del cumplimiento de los requisitos de accesibilidad por imponer una carga desproporcionada.
										<br />
										<br />
										En la Solicitud de información accesible, se debe concretar, con toda claridad, los hechos, razones y petición que permitan constatar que se trata de una solicitud razonable y legítima.
									</p>
									<h2 className="titulosAccesibility">PROCEDIMIENTO DE APLICACIÓN</h2>
									<p className="underline-offset-2">
										Si una vez realizada una solicitud de información accesible o queja, ésta hubiera sido desestimada, no se estuviera de acuerdo con la decisión adoptada, o la respuesta no cumpliera los requisitos contemplados en el artículo 12.5, la persona interesada podrá iniciar una reclamación. Igualmente se podrá iniciar una reclamación en el caso de que haya transcurrido el plazo de veinte días hábiles sin haber obtenido respuesta.
										<br />
										<br />
										La reclamación puede ser presentada través de la Instancia Genérica de la Sede electrónica del Ministerio de Asuntos Económicos y Transformación Digital , así como en el resto de opciones recogidas en la Ley 39/ 2015, de 1 de octubre, del Procedimiento Administrativo Común de las Administraciones Públicas.  Las reclamaciones serán recibidas y tratadas por la Subdirección General de Inspección de Servicios del Ministerio.
									</p>
									<h2 className="titulosAccesibility">CONTENIDO OPCIONAL</h2>
									<p className="underline-offset-2"> Este portal esta diseñado para poder cambiar el tamaño del texto y el color, así como el fondo de la pagina mediante las opciones de configuración estándar de los navegadores.
										<br />
										<br />
										Si desea cambiar el tamaño de letra del texto en los principales navegadores gráficos utilice los siguientes menús:
									</p>
									<br />
									<br />
									<li className="py-1">{"Internet Explorer, Mozilla y Firefox: Ver > Tamaño del texto"}</li>
									<li className="py-1">{"Opera: Ver > Zoom"}</li>
									<li className="py-1">{"Safari: Ver > Hacer el texto mas grande"}</li>
									<li className="py-1">{"Chrome: Controla la pagina actual > Tamaño del texto"}</li>
									<li className="py-1">Para modificar el tamaño de todo en la pagina:
										<li className="ml-10 py-1">Ctrl + + para aumentarlo</li>
										<li className="ml-10 py-1">Ctrl + - para disminuirlo</li>
										<li className="ml-10 py-1">Ctrl + 0 restaura el tamaño original del texto</li>
										<li className="ml-10 py-1">Si lo que quiere es anular la hoja de estilos o modificar el color del texto, puede consultar la pagina How to Change Text Size or Colors de la WAI, que se puede leer traducida al español en ¿Cómo cambiar el tamaño del texto o colores?</li>
									</li>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default Accessibility;
/* eslint-disable */

import React from "react";
import { useState, useEffect } from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import { Fade, Zoom } from 'react-awesome-reveal';
import { useInView } from 'react-intersection-observer';
import hamburguesa from '../../images/burger-1.png';
import './home.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import baseURL from "../../Components/url";
import Cookies from 'js-cookie';
import moneda from "../../Components/moneda";
import logo from "../../images/logoChako.png"




const Home = () => {
  const navigate = useNavigate()

  const [ref1, inView1] = useInView({ triggerOnce: true });
  const [ref2, inView2] = useInView({ triggerOnce: true });
  const [ref3, inView3] = useInView({ triggerOnce: true });
  const [ref4, inView4] = useInView({ triggerOnce: true });
  const repeater = [1, 2, 3, 4]
  const [sucursales, setSucursales] = useState([])
  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(true);

  const obtenerSucursales = (id = 1) => {
    fetch(`${baseURL}/sucursalesGet.php?id=${id}`, {
      method: 'GET',
    })
      .then(response => response.json())
      .then(data => {
        setSucursales(data.sucursales);
        setLoading(false);

      })
      .catch(error => console.error('Error al cargar sucursales:', error));

  };


  useEffect(() => {
    Cookies.set('idCliente', 1, { expires: 1 / 3 });
    obtenerSucursales();
  }, []);

  const goToDelivery = (data) => {
    Cookies.set('idRestaurate', parseInt(data.id), { expires: 1 / 3 });
    Cookies.set('idPostalCode', 0, { expires: 1 / 3 });
    navigate('/productos', { sucursal: data });
  }
  const goToReserva = (data) => {
    Cookies.set('idRestaurate', parseInt(data.id), { expires: 1 / 3 });
    navigate('/reservas', { sucursal: data });
  }

  const Burgers = (props) => {
    return (
      <div className="w-96 xl:w-96 flex flex-col font-Montserrat justify-center items-center overflow-hidden p-4 mr-10 ml-10">
        <img className="sha" src={require(`../../images/burger-${props.imagen}.png`)} alt="foto burger" />
        <h2 className="text-xl mb-4 text-center text-white font-black">{props.nombre}</h2>
        <p className=" font-[500] leading-none text-center break-words whitespace-normal">{props.descripcion}</p>
        <p className="text-lg font-bold mt-2 text-center italic">{props.precio} {moneda}</p>
      </div>
    );
  }
  const PreloaderPage = () => {
    return (
      <Fade triggerOnce>
        <div className="w-screen h-screen flex justify-center items-center bg-chako-700">
          <div className="effect">
          <img src={logo} alt="" />
          </div>
        </div >
      </Fade>
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);


  const ScrollRepeater = ({ data, text, position }) => {
    const dataToRepeater = data || []
    return (
      <div className="w-full overflow-hidden">
        <div className={position == 'left' ? 'scroll-left' : 'scroll-right'}>
          {dataToRepeater.map((item, index) => (
            <p className="inline-block" key={`repeater-${index}`}>
              {dataToRepeater.map((item, indexer) =>
              (<span key={`repeater-span-${indexer}`}>
                {text}
              </span>)
              )}
            </p>))
          }
        </div>
      </div>)
  }
  return (
    <>
      {visible ? (<PreloaderPage />) :
        (<>
          <Navbar />

          <section ref={ref1} className="bg-chako-700 w-full">
            {inView1 && (
              <Zoom triggerOnce>
                <div className="bg-chako-700 h-screen  relative">
                  <div className="absolute inset-0 flex items-center justify-center font-Montserrat font-black italic uppercase text-3xl md:text-5xl text-white pt-20">
                    <div className="flex flex-col items-center justify-center w-full">
                      <ScrollRepeater
                        data={repeater}
                        text={"necesitas un punch "}
                        position={'left'}
                      />
                      <ScrollRepeater
                        data={repeater}
                        text={"necesitas un punch "}
                        position={'right'}
                      />
                      <ScrollRepeater
                        data={repeater}
                        text={"necesitas un punch "}
                        position={'left'}
                      />
                      <ScrollRepeater
                        data={repeater}
                        text={"necesitas un punch "}
                        position={'right'}
                      />
                    </div>
                  </div>
                  <div className="absolute inset-0 flex justify-center items-center">
                    <img
                      src={hamburguesa}
                      alt="Hamburguesa"
                      className="max-w-full max-h-full object-contain z-10"
                    />
                  </div>
                </div>
                <button className="bg-white w-24s h-42 z-40"></button>
              </Zoom>
            )}
          </section>


          <section ref={ref2} className="bg-chako-700">
            {inView2 && (
              <Zoom triggerOnce>
                <div className="bg-chako-700 h-36  relative p-0">
                  <div className="absolute inset-0 flex items-center justify-center font-Montserrat font-black  uppercase text-3xl md:text-5xl text-white pt-20">
                    <div className="flex flex-col items-center justify-center w-full">
                      <ScrollRepeater
                        data={repeater}
                        text={" QUE EMPIECE EL PRIMER ROUND "}
                        position={'right'}
                      />

                    </div>
                  </div>
                </div>
                <div className=" bg-chako-700 ">
                  <div className=" inset-20 z-0 overflow-hidden flex items-center  text-white ">
                    <div className=" flex flex-col justify-center ">
                      <div className=" overflow-hidden">
                        <div className="scroll-left-2 flex  ">
                          <Burgers
                            imagen="1"
                            nombre="ROCKY"
                            descripcion={`${<span className="font-[800px]">"EDICIÓN LIMITADA"</span>}250gr de carne de solomillo y entrecot la parrilla, bacón, queso cheddar, arosde cebolla, lechuga y salsa bbq`}
                            precio=" 15.5"
                          />
                          <Burgers
                            imagen="2"
                            nombre="Maravilla"
                            descripcion="250gr de carne de solomillo y entrecot la parrilla , bacón, queso cheddar, aros decebolla, lechuga y salsa bbq"
                            precio=" 15.5"
                          />
                          <Burgers
                            imagen="3"
                            nombre="TAYSON"
                            descripcion="250gr de carne de solomillo y entrecot la parrilla , bacón, queso cheddar, aros decebolla, lechuga y salsa bbq"
                            precio=" 15.5"
                          />
                          <Burgers
                            imagen="4"
                            nombre="DRAGO"
                            descripcion="250gr de carne de solomillo y entrecot la parrilla , bacón, queso cheddar, aros decebolla, lechuga y salsa bbq"
                            precio=" 15.5"
                          />
                          <Burgers
                            imagen="1"
                            nombre="ROCKY"
                            descripcion="EDICIÓN LIMITADA: 250gr de carne de solomillo y entrecot la parrilla, bacón, queso cheddar, arosde cebolla, lechuga y salsa bbq"
                            precio=" 15.5"
                          />
                          <Burgers
                            imagen="2"
                            nombre="Maravilla"
                            descripcion="250gr de carne de solomillo y entrecot la parrilla , bacón, queso cheddar, aros decebolla, lechuga y salsa bbq"
                            precio=" 15.5"
                          />
                          <Burgers
                            imagen="3"
                            nombre="TAYSON"
                            descripcion="250gr de carne de solomillo y entrecot la parrilla , bacón, queso cheddar, aros decebolla, lechuga y salsa bbq"
                          />
                          <Burgers
                            imagen="4"
                            nombre="DRAGO"
                            descripcion="250gr de carne de solomillo y entrecot la parrilla , bacón, queso cheddar, aros decebolla, lechuga y salsa bbq"
                            precio=" 15.5"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Zoom>
            )}
          </section>

          <div className="bg-chako-700 h-36  relative p-0"> 
            <div className="absolute inset-0 flex items-center justify-center font-Montserrat font-black  uppercase text-3xl md:text-5xl text-white pt-20">
              <div className="flex flex-col items-center justify-center w-full">
                <div className="overflow-hidden w-full">
                  <div className="scroll-right">
                    <p>VISITANOS VISITANOS VISITANOS VISITANOS VISITANOS VISITANOS VISITANOS VISITANOS VISITANOS VISITANOS VISITANOS </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section ref={ref3} id="restaurantes" className="bg-chako-700">
            {inView3 && (<Zoom triggerOnce>
              <div className="flex mx-auto w-full gap-5 justify-center items-center my-7 xl:flex-row flex-col ">
                {sucursales.map((sucursal) => (
                  <div key={sucursal.id} className="w-72 h-96 bg-white rounded-md relative">
                    <div className="rounded-md">
                      <img src={require("../../images/backgraund-2-png.png")} alt="" className="rounded-t-md" />
                    </div>
                    <div className="font-Montserrat p-5 text-[#5c5959]">
                      <h4 className="font-black flex gap-2 items-center">
                        <FontAwesomeIcon icon={faLocationDot} />
                        <span>{sucursal.nombre}</span>
                      </h4>
                      <div className="flex gap-3 mt-4 ">
                        <p>Dirección: {sucursal.direccion}</p>
                      </div>
                      <div className="flex mt-7 ">
                        {parseInt(sucursal.entrega) > 0 &&

                          <button
                            style={{ width: parseInt(sucursal.mesas) > 0 ? '50%' : '100%' }}
                            className=" uppercase h-10 w-1/2 text-white bg-gradient-to-r from-chako-500 via-chako-600 to-chako-700 hover:bg-gradient-to-br font-bold rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                            onClick={() => goToDelivery(sucursal)}
                          >
                            Delivery
                          </button>
                        }
                        {
                          parseInt(sucursal.mesas) > 0 &&
                          <button
                            style={{ width: parseInt(sucursal.entrega) > 0 ? '50%' : '100%' }}
                            className=" uppercase h-10 text-white bg-gradient-to-r from-chako-500 via-chako-600 to-chako-700 hover:bg-gradient-to-br font-bold rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                            onClick={() => goToReserva(sucursal)}
                          >
                            Reserva
                          </button>
                        }
                      </div>

                    </div>
                  </div>
                ))}
              </div>
            </Zoom>)}
          </section>
          
              
              <Footer />
              
            
        </>

        )
      }
    </>
  );
};

export default Home;

import React, { useEffect, useState, useRef } from 'react';
import baseURL from '../url';
import './Products.css';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faAngleDoubleRight, faHeart, faLocationArrow, faLocationDot, faClock} from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProductosLoading from '../ProductosLoading/ProductosLoading';
import { Link as Anchor } from "react-router-dom";
import moneda from '../moneda';
import Cookies from 'js-cookie';
 


SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function Products() {
  const [categorias, setCategorias] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [fixedCategories, setFixedCategories] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [productoSeleccionado, setProductoSeleccionado] = useState(null);
  const [cantidad, setCantidad] = useState(1); // Estado para la cantidad de productos en el carrito
  const categoriasRefs = useRef([]);
  const categoriasInputRef = useRef(null);
  const swiperRef = useRef(null);
  const [productos, setProductos] = useState([]);
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState('Todo');
  const [favoritos, setFavoritos] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');
  const [formModalIsOpen, setFormModalIsOpen] = useState(true);
  const [listOfRestaurant, setListOfRestaurant] = useState([])
  const [filteredRestaurant, setFilteredRestaurant] = useState([])
  const [idPostalCode, setIdPostalCode] = useState(() => Cookies.get('idPostalCode') || 0);
  const [idRestaurate, setIdRestaurante] = useState(() => Cookies.get('idRestaurate') || 0);
  const [isPostalSelected, setIsPostalSelected] = useState(false); // Verifica si hay un restaurante seleccionado
  const [sucursales, setSucursales] = useState([])
  const [modalPedido, setModalPedido] = useState(idRestaurate === 0);

  const [allPostalCodes, setAllPostalCodes ] = useState([])
  const [postalCodes, setPostalCodes ] = useState([])
  
  const [selectedPostalCode, setSelectedPostalCode] =  useState(idPostalCode)
  const [selectedRestaurante, setSelectedRestaurante] = useState(sucursales.find(restaurante => restaurante.id === Number(idRestaurate)));
 

   const obtenerSucursales = () => {
    fetch(`${baseURL}/sucursalesGet.php`, {
      method: 'GET',
    })
      .then(response => response.json())
      .then(data => {
        const sucursalesAll = data.sucursales
        const postalCodesAll = []
        sucursalesAll.map(suc => {
          const zips = suc['zip'].split(',') || []
          zips.map( z => z!=''?postalCodesAll.push(z):'' ) 
        })
        if(idRestaurate > 0){ 
          setSelectedRestaurante(sucursalesAll.find(restaurante => restaurante.id == Number(idRestaurate)))
          setPostalCodes(postalCodesAll.filter(z=>{
            const selected = sucursalesAll.find(suc => suc.id == idRestaurate) || {}
            const zips = selected['zip']?.split(',') || []
            const exist = zips.filter(zip => zip == z)
            return exist.length > 0
          }))
        } else {
          setPostalCodes(postalCodesAll) 
        }
        setAllPostalCodes(postalCodesAll) 
        setSucursales(sucursalesAll); 
        console.log('idRestaurate',idRestaurate)
        console.log('idPostalCode',idPostalCode)
        console.log('postalCodesAll',postalCodesAll)
        console.log('postalCodes')
      })
      .catch(error => console.error('Error al cargar sucursales:', error)); 
  };

  
   useEffect(() => {
     if (idPostalCode != 0 && idRestaurate != 0) {

       setModalPedido(false); 

     } else {

       setModalPedido(true);

     }
   }, [idPostalCode, idRestaurate]);
 
   
   useEffect(() => {
     if (idPostalCode != 0) {
       Cookies.set('idPostalCode', idPostalCode, { expires: 1 / 3 }); 
       setSelectedPostalCode(idPostalCode)
      }
   }, [idPostalCode]);
 
   
   useEffect(() => {
     if (idRestaurate != parseInt(0)) {
       Cookies.set('idRestaurate', idRestaurate, { expires: 1 / 3 }); 
       setSelectedRestaurante(sucursales.find(suc=>suc.id == idRestaurate))
     }
   }, [idRestaurate]);

 

   const handlePostalSelection = (postalId) => {
     const restaurantTemp = sucursales.filter(s=> s?.postalCodes?.find( i => i == postalId))
     setIdPostalCode(postalId);
     setIdRestaurante(restaurantTemp)
     setSelectedRestaurante(restaurantTemp)
     setSelectedPostalCode(postalId)
     setIsPostalSelected(true);
   };
 
 
   const filteredRestaurants = idPostalCode
     ? sucursales.filter(res => {
        const zips = res['zip'].split(',') || []
        const exist = zips.filter(z => z == idPostalCode) || []
        return exist.length > 0
     })
     : [];


  
  const handleClickCategoria = (categoria) => {
    setCategoriaSeleccionada(categoria);
  };

  const handleList = (lista) => {
    setListOfRestaurant(lista);
  };

  useEffect(() => {

    cargarFavoritos();
    //cargarProductos();
    obtenerSucursales();


    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > categoriasInputRef?.current?.offsetTop) {
      setFixedCategories(true);
    } else {
      setFixedCategories(false);
    }
  };



  const cargarProductos = (id = 1) => {
    fetch(`${baseURL}/productosGet.php?id=${id}`, {
      method: 'GET',
    })
      .then(response => response.json())
      .then(data => {
        const categoriasMap = new Map();
        data.productos.forEach(producto => {
          const categoria = producto.categoria;
          if (categoriasMap.has(categoria)) {
            categoriasMap.get(categoria).push(producto);
          } else {
            categoriasMap.set(categoria, [producto]);
          }
        });
        const categoriasArray = Array.from(categoriasMap, ([categoria, productos]) => ({ categoria, productos }));
        setCategorias(categoriasArray);
        setLoading(false);
        setProductos(data.productos); // Guardamos todos los productos
      })
      .catch(error => console.error('Error al cargar productos:', error));
  };

  const obtenerImagen = (item) => {
    if (item.imagen1) {
      return item.imagen1;
    } else if (item.imagen2) {
      return item.imagen2;
    } else if (item.imagen3) {
      return item.imagen3;
    } else if (item.imagen4) {
      return item.imagen4;
    }
    return null;
  };

  const openModal = (producto) => {
    setProductoSeleccionado(producto);
    setModalIsOpen(true);
    const cargarFavoritos = () => {
      const storedFavoritos = JSON.parse(localStorage.getItem('favoritos')) || [];
      setFavoritos(storedFavoritos);
    };
    cargarFavoritos()
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCantidad(1);
    setSelectedItem('')
  };
  const cargarFavoritos = () => {
    const storedFavoritos = JSON.parse(localStorage.getItem('favoritos')) || [];
    setFavoritos(storedFavoritos);
  };

  const addToCart = () => {
    if (productoSeleccionado) {
      const cart = JSON.parse(localStorage.getItem('cart')) || [];

      // Verificar si existe un producto con el mismo ID en el carrito
      const existingItemIndex = cart.findIndex(item =>
        item.idProducto === productoSeleccionado.idProducto
      );

      if (existingItemIndex !== -1) {
        // Si el producto ya existe en el carrito, agregamos el nuevo sabor al array de sabores
        const existingItem = cart[existingItemIndex];
        const updatedSabores = [...existingItem.talle, selectedItem]; // Agregar el nuevo sabor

        // Actualizar la cantidad del producto existente en el carrito
        const updatedCantidad = existingItem.cantidad + cantidad;

        // Actualizar el producto existente en el carrito con el nuevo sabor y cantidad
        cart[existingItemIndex] = { ...existingItem, talle: updatedSabores, cantidad: updatedCantidad };
      } else {
        // Si el producto no existe en el carrito, lo agregamos con el sabor seleccionado
        cart.push({ idProducto: productoSeleccionado.idProducto, talle: [selectedItem], cantidad });
      }

      // Actualizamos el carrito en el localStorage
      localStorage.setItem('cart', JSON.stringify(cart));

      // Llamamos a la función openModal() con la información del producto añadido
      openModal({ ...productoSeleccionado, cantidad });

      // Agregamos la llamada a cargarProductos para actualizar la lista de productos en Products
      cargarProductos(idRestaurate);
      toast.success('Producto agregado');

    }
  };



  const incrementCantidad = () => {
    setCantidad(cantidad + 1);
  };

  const decrementCantidad = () => {
    if (cantidad > 1) {
      setCantidad(cantidad - 1);
    }
  };

  const addSpaces = texto => texto.split(' ').join(' ') 

  const agregarAFavoritos = (idProducto) => {
    const favList = [...favoritos];
    const index = favList.indexOf(idProducto);
    if (index === -1) {
      // Si el producto no está en favoritos, lo agregamos
      favList.push(idProducto);
      setFavoritos(favList);
      localStorage.setItem('favoritos', JSON.stringify(favList));
      console.log('Producto agregado a favoritos');

    } else {
      // Si el producto está en favoritos, lo eliminamos
      favList.splice(index, 1);
      setFavoritos(favList);
      localStorage.setItem('favoritos', JSON.stringify(favList));
      console.log('Producto eliminado de favoritos');
    }
  };
  const changeZone = () => {
    Cookies.set('idPostalCode', 0, { expires: 1 / 3 }); 
    Cookies.set('idRestaurate', 0, { expires: 1 / 3 }); 
    setPostalCodes(allPostalCodes)
    setSelectedPostalCode(0)
    setIsPostalSelected(false)
    setIdPostalCode(0)
    setIdRestaurante(0)

  }
  const handlerPostalContinue = () => {
    setIsPostalSelected(true)
  }
  useEffect(() => {

    const temporal = sucursales.filter((item) => {
      return listOfRestaurant.find((a) => a === item.id)
    })
    setFilteredRestaurant(temporal)

  }, [listOfRestaurant])

  
  useEffect(() => {
    cargarProductos(idRestaurate)

  }, [idRestaurate])


  return (<>

      
    {loading ? ( <ProductosLoading /> ) :
    ( <div>
        {idPostalCode !== 0 && idRestaurate !== 0 && (
          <div 
            className='relative bg-[#0005] text-white top-[-50px] md:top-[-40px]  p-5  flex h-12 md:h-5   font-Montserrat  font-bold text-[10px]  items-center'>
            <div className='flex flex-row items-center gap-1 md:gap-2' >
              <FontAwesomeIcon icon={faLocationDot} className='text-chako-700 justify-center text-[20px]'/>
              <span className='mx-2'>
                {selectedRestaurante?.nombre}
              </span>
              <FontAwesomeIcon icon={faClock} className='text-chako-700 mt-[2px] text-[20px]' />
              <span className='mx-2'>{selectedRestaurante?.horarios}</span>
              <FontAwesomeIcon icon={faLocationArrow} className='text-chako-700 mt-[2px] text-[20px]' /> 
              <span className='mx-2'> Zona de entrega: {selectedPostalCode}</span>
              <button className=' bg-red-600 px-1 py-1 md:px-4 md:py-2 rounded ' onClick={changeZone}>
                <span>Cambiar Zona</span>
              </button>
            </div>
          </div>
        )}
      </div>
    )}
    { 
     modalPedido &&
     <div className={`h-full w-full fixed top-0 right-0 left-0 bg-[#000A] z-50 flex justify-center items-center`}>
       <div className='relative h-3/4 container text-center bg-white rounded-2xl'>
         {
           idPostalCode == 0 && (
            <div>
              <h2 className='font-bold mt-2 mb-2'>Seleccione Su Ubicacion</h2>
              <div className='flex justify-center items-center m-10 mb-20 mt-20'>
                <div className='w-3/4 flex flex-wrap gap-5'>
                  {postalCodes.map((postal,index) => (
                    <button
                      key={`postal-id-${postalCodes}-${index}`}
                      onClick={() => handlePostalSelection(postal)}
                      style={{ background: idPostalCode === postal? '' : 'gray' }}
                      className='uppercase h-10 w-20 text-white bg-gradient-to-r from-chako-500 via-chako-600 to-chako-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-chako-300 dark:focus:ring-chako-800 font-black rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2'
                    >
                      {postal}
                    </button>
                  ))}
                </div>
              </div>
              <button
                disabled={idPostalCode === 0}
                className={`${idPostalCode === 0 ? 'bg-gray-700' : 'bg-chako-700'} w-80 h-10 m-3 rounded-3xl text-white font-bold`}
              > Recoger </button>
              <button
                onClick={() => setIsPostalSelected(true)}
                disabled={idPostalCode === 0}
                className={`${idPostalCode === 0 ? 'bg-gray-700' : 'bg-chako-700'} w-80 h-10 m-3 rounded-3xl text-white font-bold`}
              > Delivery </button>
            </div>
           )  
         }
          {
            (idPostalCode > 0 && !idRestaurate == 0) &&  
            ( <div>
               <h2 className='font-bold mt-2 mb-2'>Seleccione Un Restaurant</h2>
               <div className='flex justify-center items-center m-10 mb-20 mt-20'>
                 <div className='w-3/4 flex flex-col gap-5'>
                   {filteredRestaurants.map((restaurante) => (
                     <button
                       key={restaurante.id}
                       onClick={() => setIdRestaurante(restaurante.id)}
                       style={{ background: idRestaurate === restaurante.id ? '' : 'gray' }}
                       className='uppercase h-20 text-white bg-gradient-to-r from-chako-500 via-chako-600 to-chako-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-chako-300 dark:focus:ring-chako-800 font-black rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2'
                     >
                       {restaurante.nombre}
                     </button>
                   ))}
                 </div>
               </div>
               <button
                 onClick={() => setModalPedido(false)}
                 disabled={idRestaurate === 0}
                 className={`${idRestaurate === 0 ? 'bg-gray-700' : 'bg-chako-700'} w-1/2 h-10 rounded-3xl text-white font-bold`}
               > seleccionar </button>
             </div> ) 
         }
       </div>
     </div>
    }


    <div className='ProductsContain'>

      <ToastContainer />
      <div>
        {loading ? (
          <ProductosLoading />
        ) : (
          <div >
            <h3 className='font-Rockin text-2xl text-white ml-4 mt-5'>LO MAS VENDIDO</h3>
            {categoriaSeleccionada === 'Todo' && (
              <div className='Products'>
                {productos.some(item => item.masVendido === "si") && (
                  <div className='categoriSection'>

                    

                    <Swiper
                      effect={'coverflow'}
                      grabCursor={true}
                      loop={true}
                      slidesPerView={'auto'}
                      coverflowEffect={{ rotate: 0, stretch: 0, depth: 100, modifier: 2.5 }}
                      navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
                      autoplay={{ delay: 2000 }}
                      onSwiper={(swiper) => {
                        swiperRef.current = swiper;
                      }}
                      id='swiper_container'
                      className='pl-4 '
                    >

                      {productos.filter(item => item.masVendido === "si").map(item => (
                        <SwiperSlide className="w-72 flex flex-col justify-center items-center overflow-hidden mx-2 sm:mx-5 " id='SwiperSlide-scroll-products' key={item.idProducto}>
                          <Anchor className="flex flex-col items-center  " to={`/producto/${item.idProducto}/${item.titulo.replace(/\s+/g, '-')}`}>
                            <div className='w-full'>
                              <h6 className='bg-white absolute p-2 text-chako-700 font-black text-right '>Mas Vendido</h6>
                            </div>
                            <img src={obtenerImagen(item)} alt="imagen" className='w-52 h-48' />
                            <div className=''>
                              <h4 className="text-xl   mb-4 text-center  text-white font-black">{item.titulo}</h4>
                              <h2 className=" font-thin leading-none text-white  text-center break-words whitespace-normal">{item.descripcion}</h2>
                              <div className='deFLexPrsice'>
                                <h5 className="text-lg font-bold mt-2 text-white text-center italic ">{moneda} {item?.precio}</h5>
                                {
                                  (item?.precioAnterior > 0 && item?.precioAnterior !== undefined) && (
                                    <h5 className='text-lg font-bold mt-2 text-white text-center italic line-through'>{moneda} {item?.precioAnterior}</h5>
                                  )
                                }
                              </div>
                            </div>

                          </Anchor>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>

                )}

                {categorias.map(({ categoria, productos }, index) => (
                  <div key={categoria} className='categoriSection' ref={ref => categoriasRefs.current[index] = ref}>

                    <div className='deFlexTitlesection'>
                      <span className='font-Rockin text-2xl text-white'> {addSpaces(categoria)} </span>

                    </div>

                    <Swiper
                      effect={'coverflow'}
                      grabCursor={true}
                      loop={true}
                      slidesPerView={'auto'}
                      coverflowEffect={{ rotate: 0, stretch: 0, depth: 100, modifier: 2.5 }}
                      navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
                      autoplay={{ delay: 3000, reverseDirection: (index % 2 === 0 ? true : false) }}
                      onSwiper={(swiper) => {
                        swiperRef.current = swiper;
                      }}
                      id='swiper_container'
                      className='pl-4 '
                    >

                      {productos.map(item => (
                        <SwiperSlide className="w-72 flex flex-col justify-center items-center overflow-hidden " id='SwiperSlide-scroll-products' key={item.idProducto}>
                          <Anchor className='flex w-72 flex-col items-center ' key={item.idProducto} to={`/producto/${item.idProducto}/${item.titulo.replace(/\s+/g, '-')}`}>
                            <img className='w-52 h-48' src={obtenerImagen(item)} alt="imagen" />
                            <div className=''>
                              <h4 className="text-xl mb-4 text-center  text-white font-black" >{item.titulo}</h4>
                              <p className="font-[500] leading-none text-white text-center  break-words ">{item.descripcion}</p>
                              <div className='w-full'>
                                <h2 className="text-lg font-bold mt-2 text-white text-center italic ">{moneda} {item?.precio}</h2>
                                {
                                  (item?.precioAnterior > 0 && item?.precioAnterior !== undefined) && (
                                    <h2 className='text-lg font-bold mt-2 text-white text-center italic line-through'>{moneda} {item?.precioAnterior}</h2>
                                  )
                                }
                              </div>

                            </div>
                          </Anchor>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                ))}
              </div>
            )}


            <div className='categoriSectionSelected'>
              {productos
                // Filtra los productos solo para la categoría seleccionada
                .filter(item => categoriaSeleccionada !== 'Todo' && item.categoria === categoriaSeleccionada)
                // Mapea para renderizar los productos dentro de la categoría
                .map(item => (
                  <Anchor key={item.idProducto} to={`/producto/${item.idProducto}/${item.titulo.replace(/\s+/g, '-')}`} >

                    <div className='cardProdcutSelected' onClick={() => openModal(item)}>
                      <img src={obtenerImagen(item)} alt="imagen" />
                      <div className='cardTextSelected'>
                        <h4>{item.titulo}</h4>
                        <span>{item.descripcion}</span>
                        <div className='deFLexPrice'>
                          <h5>{moneda} {item?.precio}</h5>
                          {
                            (item?.precioAnterior > 0 && item?.precioAnterior !== undefined) && (
                              <h5 className='precioTachado'>{moneda} {item?.precioAnterior}</h5>
                            )
                          }
                        </div>

                        <FontAwesomeIcon icon={faAngleDoubleRight} className='iconCard' />
                      </div>
                    </div>
                  </Anchor>
                ))}
            </div> 

          </div>
        )}

      </div>
      <Modal isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal-detail"
        overlayClassName="overlay-detail">
        {productoSeleccionado && (
          <div className='modal-content-detail'>
            <div className='backFLex'>
              <button onClick={closeModal} className='back'>  <FontAwesomeIcon icon={faArrowLeft} /></button>
              <button onClick={() => agregarAFavoritos(productoSeleccionado.idProducto)} className='favoritos-btn'>
                <FontAwesomeIcon icon={faHeart} style={{ color: favoritos.includes(productoSeleccionado.idProducto) ? 'red' : 'gray' }} />
              </button>
            </div>
            <Swiper
              effect={'coverflow'}
              grabCursor={true}
              loop={true}
              slidesPerView={'auto'}
              coverflowEffect={{ rotate: 0, stretch: 0, depth: 100, modifier: 2.5 }}
              navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
              autoplay={{ delay: 3000 }}
              pagination={{ clickable: true }}
              onSwiper={(swiper) => {

                swiperRef.current = swiper;
              }}
              id='swiper_container_Imgs'
            >
              {productoSeleccionado.imagen1 ? (
                <SwiperSlide id='SwiperSlide-scroll-img'>
                  <img src={productoSeleccionado.imagen1} alt="" />
                </SwiperSlide>
              ) : (
                null
              )}

              {productoSeleccionado.imagen2 ? (
                <SwiperSlide id='SwiperSlide-scroll-img'>
                  <
                    img src={productoSeleccionado.imagen2} alt="" />
                </SwiperSlide>
              ) : (
                null
              )}
              {productoSeleccionado.imagen3 ? (
                <SwiperSlide id='SwiperSlide-scroll-img'>
                  <img src={productoSeleccionado.imagen3} alt="" />
                </SwiperSlide>
              ) : (
                null
              )}
              {productoSeleccionado.imagen4 ? (
                <SwiperSlide id='SwiperSlide-scroll-img'>
                  <img src={productoSeleccionado.imagen4} alt="" />
                </SwiperSlide>
              ) : (
                null
              )}
            </Swiper>
            <div className='modalText'>
              <h2>{productoSeleccionado.titulo}</h2>
              <p>{productoSeleccionado.categoria}</p>
              <p>{productoSeleccionado.descripcion} </p>
              <div className='deFLexPrice'>
                <h5>${`${productoSeleccionado?.precio}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                {
                  (productoSeleccionado.precioAnterior !== 0 && productoSeleccionado.precioAnterior !== undefined) && (
                    <h5 className='precioTachado'>${`${productoSeleccionado?.precioAnterior}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                  )
                }
              </div>

              <div className='itemsDetail'>


                {productoSeleccionado.item1 && (
                  <label>
                    <input
                      type="radio"
                      name="talle"
                      value={productoSeleccionado.item1}
                      checked={selectedItem === productoSeleccionado.item1}
                      onChange={(e) => setSelectedItem(e.target.value)}
                    />
                    {productoSeleccionado.item1}
                  </label>
                )}
                {productoSeleccionado.item2 && (
                  <label>
                    <input
                      type="radio"
                      name="talle"
                      value={productoSeleccionado.item2}
                      checked={selectedItem === productoSeleccionado.item2}
                      onChange={(e) => setSelectedItem(e.target.value)}
                    />
                    {productoSeleccionado.item2}
                  </label>
                )}
                {productoSeleccionado.item3 && (
                  <label>
                    <input
                      type="radio"
                      name="talle"
                      value={productoSeleccionado.item3}
                      checked={selectedItem === productoSeleccionado.item3}
                      onChange={(e) => setSelectedItem(e.target.value)}
                    />
                    {productoSeleccionado.item3}
                  </label>
                )}
                {productoSeleccionado.item4 && (
                  <label>
                    <input
                      type="radio"
                      name="talle"
                      value={productoSeleccionado.item4}
                      checked={selectedItem === productoSeleccionado.item4}
                      onChange={(e) => setSelectedItem(e.target.value)}
                    />
                    {productoSeleccionado.item4}
                  </label>
                )}
                {productoSeleccionado.item5 && (
                  <label>
                    <input
                      type="radio"
                      name="talle"
                      value={productoSeleccionado.item5}
                      checked={selectedItem === productoSeleccionado.item5}
                      onChange={(e) => setSelectedItem(e.target.value)}
                    />
                    {productoSeleccionado.item5}
                  </label>
                )}
                {productoSeleccionado.item6 && (
                  <label>
                    <input
                      type="radio"
                      name="talle"
                      value={productoSeleccionado.item6}
                      checked={selectedItem === productoSeleccionado.item6}
                      onChange={(e) => setSelectedItem(e.target.value)}
                    />
                    {productoSeleccionado.item6}
                  </label>
                )}
                {productoSeleccionado.item7 && (
                  <label>
                    <input
                      type="radio"
                      name="talle"
                      value={productoSeleccionado.item7}
                      checked={selectedItem === productoSeleccionado.item7}
                      onChange={(e) => setSelectedItem(e.target.value)}
                    />
                    {productoSeleccionado.item7}
                  </label>
                )}
                {productoSeleccionado.item8 && (
                  <label>
                    <input
                      type="radio"
                      name="talle"
                      value={productoSeleccionado.item8}
                      checked={selectedItem === productoSeleccionado.item8}
                      onChange={(e) => setSelectedItem(e.target.value)}
                    />
                    {productoSeleccionado.item8}
                  </label>
                )}
                {productoSeleccionado.item9 && (
                  <label>
                    <input
                      type="radio"
                      name="talle"
                      value={productoSeleccionado.item9}
                      checked={selectedItem === productoSeleccionado.item9}
                      onChange={(e) => setSelectedItem(e.target.value)}
                    />
                    {productoSeleccionado.item9}
                  </label>
                )}
                {productoSeleccionado.item10 && (
                  <label>
                    <input
                      type="radio"
                      name="talle"
                      value={productoSeleccionado.item10}
                      checked={selectedItem === productoSeleccionado.item10}
                      onChange={(e) => setSelectedItem(e.target.value)}
                    />
                    {productoSeleccionado.item10}
                  </label>
                )}
              </div>

            </div>

            <div className='deFlexGoTocart'>
              <div className='deFlexCart'>
                <button onClick={decrementCantidad}>-</button>
                <span>{cantidad}</span>
                <button onClick={incrementCantidad}>+</button>
              </div>
              <button onClick={addToCart} className='btn'>Agregar  (  ${`${productoSeleccionado?.precio * cantidad}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")} )</button>
            </div>
          </div>
        )}
      </Modal>
        
       
    </div>
  </>
  );
}

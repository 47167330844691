import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import baseURL from '../url';
import './Cart.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faClockRotateLeft, faClose, faHistory, faShoppingCart, faTimesCircle, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Link as Anchor } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moneda from '../moneda';
import Swal from 'sweetalert2';
import contador from '../contador'
import Cookies from 'js-cookie';

export default function Cart() {
	const [cartItems, setCartItems] = useState([]);
	const [productos, setProductos] = useState([]);
	const [loading, setLoading] = useState(true);
	const [modalPedidosIsOpen, setModalPedidosIsOpen] = useState(false);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [modalIsOpen2, setModalIsOpen2] = useState(false);
	const [modalIsOpen3, setModalIsOpen3] = useState(false);
	const [isFocused, setIsFocused] = useState(false);
	const [noteText, setNoteText] = useState('');
	const [location, setLocation] = useState('');
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [codigo, setCodigo] = useState('');
	const [contactos, setContactos] = useState([]);
	const [pedidos, setPedidos] = useState([]);
	const [descuento, setDescuento] = useState(0);
	const [codigoValido, setCodigoValido] = useState(false);
	const [totalPrice, setTotalPrice] = useState(0);
	const [paymentMethod, setPaymentMethod] = useState('efectivo');
	const [deliveryOption, setDeliveryOption] = useState('delivery');
	const [idCliente, setIdCliente] = useState(() => Cookies.get('idCliente') || 0);
	const [sucursales, setSucursales] = useState([]);
	const [idRestaurate, setIdRestaurante] = useState(() => Cookies.get('idRestaurate') || 0);






	const cargarSucursales = () => {
		fetch(`${baseURL}/sucursalesGet.php`, {
			method: 'GET',
		})
			.then(response => response.json())
			.then(data => {
				setSucursales(data.sucursales || []);
				console.log(data.sucursales)
			})
			.catch(error => console.error('Error al cargar sucursales:', error));
	};
	const cargarPedidos = () => {
		fetch(`${baseURL}/userPedidos.php?idCliente=${idCliente}`, {
			method: 'GET',
		})
			.then(response => response.json())
			.then(data => {
				setPedidos(data.pedidos.reverse() || []);
			})
			.catch(error => console.error('Error al cargar pedidos:', error));
	};




	useEffect(() => {
		cargarSucursales()
		cargarPedidos()
		cargarContacto();
	}, []);
	useEffect(() => {
		// Calcular el precio total al cargar el carrito o al actualizar los productos
		let totalPriceCalc = 0;
		cartItems.forEach(item => {
			totalPriceCalc += item.precio * item.cantidad;
		});
		setTotalPrice(totalPriceCalc);
	}, [cartItems]);

	const cargarContacto = () => {
		fetch(`${baseURL}/contactoGet.php`, {
			method: 'GET',
		})
			.then(response => response.json())
			.then(data => {
				setContactos(data.contacto.reverse()[0] || []);
			})
			.catch(error => console.error('Error al cargar contactos:', error));
	};

	useEffect(() => {
		cargarProductos();
	}, [isFocused]);

	useEffect(() => {
		const fetchCartItems = async () => {
			const cart = JSON.parse(localStorage.getItem('cart')) || [];
			const promises = cart.map(async (cartItem) => {
				const producto = productos.find(producto => producto.idProducto === cartItem.idProducto);
				return {
					...producto,
					cantidad: cartItem.cantidad,
					item: cartItem.item,
				};
			});

			Promise.all(promises)
				.then((items) => {
					setCartItems(items);
					setLoading(false);
				})
				.catch((error) => {
					console.error('Error al obtener detalles del carrito:', error);
					setLoading(false);
				});
		};

		fetchCartItems();
	}, [productos, isFocused]);

	const cargarProductos = () => {
		fetch(`${baseURL}/productosGet.php`, {
			method: 'GET',
		})
			.then(response => response.json())
			.then(data => {
				setProductos(data.productos || []);
			})
			.catch(error => console.error('Error al cargar productos:', error));
	};

	const obtenerImagen = (item) => {
		return item.imagen1 || item.imagen2 || item.imagen3 || item.imagen4 || null;
	};

	const openModal = () => {
		setModalIsOpen(true);
		setIsFocused(true);
	};
	const openModalPedidos = () => {
		setModalPedidosIsOpen(true);
		setIsFocused(true);
	};
	const closePedidosModal = () => {
		setModalPedidosIsOpen(false);
		setIsFocused(false);
	};


	const closeModal = () => {
		setModalIsOpen(false);
		setIsFocused(false);
	};

	const openModal2 = () => {
		setModalIsOpen2(true);
	};

	const closeModal2 = () => {
		setModalIsOpen2(false);
	};
	const openModal3 = () => {
		setModalIsOpen3(true);
	};

	const closeModal3 = () => {
		setModalIsOpen3(false);
	};
	const removeFromCart = (id) => {
		const updatedCart = cartItems.filter(item => item.idProducto !== id);
		setCartItems(updatedCart);
		localStorage.setItem('cart', JSON.stringify(updatedCart));
	};

	const clearCart = () => {
		setCartItems([]);
		localStorage.removeItem('cart');
	};

	const [codigos, setCodigos] = useState([]);

	useEffect(() => {
		cargarCodigos();

	}, []);

	const cargarCodigos = () => {
		fetch(`${baseURL}/codigosGet.php`, {
			method: 'GET',
		})
			.then(response => response.json())
			.then(data => {
				setCodigos(data.codigos || []);
			})
			.catch(error => console.error('Error al cargar códigos:', error));
	};

	//console.log(cargarCodigos)



	// Función para aumentar la cantidad de un producto en el carrito
	const increaseQuantity = (index) => {
		const updatedCartItems = [...cartItems];
		updatedCartItems[index].cantidad += 1;
		setCartItems(updatedCartItems);
		localStorage.setItem('cart', JSON.stringify(updatedCartItems));
	};

	// Función para disminuir la cantidad de un producto en el carrito
	const decreaseQuantity = (index) => {
		const updatedCartItems = [...cartItems];
		if (updatedCartItems[index].cantidad > 1) {
			updatedCartItems[index].cantidad -= 1;
			setCartItems(updatedCartItems);
			localStorage.setItem('cart', JSON.stringify(updatedCartItems));
		}
	};


	/* realizar pedidos------------------------*/
	const [estado, setEstado] = useState('Pendiente');

	const [mensaje, setMensaje] = useState('');
	const [selectedMesa, setSelectedMesa] = useState('');

	const crearPedido = async () => {
		setMensaje('Procesando...');
		if (name == '') {
			if (!toast.isActive(13, "friendRequest")) {
				toast.error('Debe indicar el nombre', { autoClose: 2000 });
			}

			return
		}
		try {
			// Construir la lista de productos del pedido
			const productosPedido = cartItems.map(item => {
				return {
					titulo: item.titulo,
					cantidad: item.cantidad,
					item: item.item,
					categoria: item.categoria,
					precio: item.precio,
					imagen: obtenerImagen(item)

				}
			});
			// Convertir la lista de productos a JSON
			const productosPedidoJSON = JSON.stringify(productosPedido);
			// Calcular el precio total del pedido
			let totalPrice = 0;
			cartItems.forEach(item => {
				totalPrice += item.precio * item.cantidad;
			});

			// Obtener el descuento del código de descuento
			const codigoDescuento = codigos.find(item => item.codigo === codigo);
			let descuentoCodigo = 0;

			if (codigoDescuento) {
				descuentoCodigo = codigoDescuento.descuento;
			}

			// Aplicar el descuento del código de descuento
			const totalPriceWithDiscount = totalPrice - descuentoCodigo;

			// Enviar el pedido con el precio total descontado
			const formData = new FormData();
			formData.append('idMesa', 0);
			formData.append('estado', estado);
			formData.append('productos', productosPedidoJSON);
			formData.append('total', totalPriceWithDiscount);
			formData.append('usuario', idCliente);
			formData.append('sucursal', idRestaurate);
			formData.append('nombre', name);
			formData.append('email', email);
			formData.append('nota', noteText);
			formData.append('codigo', codigo);
			const response = await fetch(`${baseURL}/pedidoPost.php`, {
				method: 'POST',
				body: formData,
			});

			const data = await response.json();
			if (data.mensaje) {
				setMensaje('');
				Swal.fire(
					'Pedido enviado!',
					data.mensaje,
					'success'
				);
				setName('')
				setEmail('')
				setCodigo('')
				setNoteText('')
				closeModal()
				closeModal2()
				closeModal3()
				clearCart()
				cargarPedidos()
				openModalPedidos()
			} else if (data.error) {
				setMensaje('');
				if (!toast.isActive(13, "friendRequest")) {

					toast.error(data.error, { autoClose: 1000 });
				}
			}
		} catch (error) {
			console.error('Error:', error);
			setMensaje('');
			if (!toast.isActive(13, "friendRequest")) {

				toast.error('Error de conexión. Por favor, inténtelo de nuevo.', { autoClose: 1000 });
			}
		}
	};

	const [counter, setCounter] = useState(contador);
	const [isPaused, setIsPaused] = useState(false);
	useEffect(() => {
		const interval = setInterval(() => {
			if (!isPaused) {
				setCounter((prevCounter) => {
					if (prevCounter === 1) {
						recargar();
						return contador;
					}
					return prevCounter - 1;
				});
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [isPaused]);
	const togglePause = () => {
		setIsPaused(!isPaused);
	};
	const recargar = () => {


	};
	useEffect(() => {
		const interval = setInterval(() => {
			cargarPedidos()

		}, 15000);
		return () => clearInterval(interval);
	}, [])

	useEffect(() => {
		if (modalPedidosIsOpen) {
			setModalIsOpen(false)
		}
	}, [modalPedidosIsOpen])

	useEffect(() => {
		if (modalIsOpen) {
			setModalPedidosIsOpen(false)
		}
	}, [modalIsOpen])




	return (
		<div>
			<ToastContainer containerId={"friendRequest"} />
			<button onClick={openModal} className='cartIconFixed'>
				{
					cartItems?.length >= 1 && (
						<span className='-mr-5 h-[22px] w-[22px] font-[500] p-0 text-center'>
							{cartItems.length}
						</span>
					)

				}
				<FontAwesomeIcon icon={faShoppingCart} />
			</button>
			<button onClick={openModalPedidos} className='cartIconFixed bottom-[120px]'>
				{
					pedidos?.length >= 1 && (
						<span className='-mr-5 h-[22px] w-[22px] font-[500] p-0 text-center'>
							{(pedidos.filter(p => p.estado != 'Entregado' && p.estado != 'Rechazado')).length}
						</span>
					)

				}
				<FontAwesomeIcon icon={faHistory} />
			</button>

			<Modal
				isOpen={modalIsOpen}
				className="modal-cart"
				overlayClassName="overlay-cart"
				onRequestClose={closeModal}
				ariaHideApp={false}
			>
				<div className='deFLex'>
					<button onClick={closeModal} ><FontAwesomeIcon icon={faArrowLeft} />  </button>
					<button onClick={clearCart} className='deleteToCart font-[500]'>Vaciar carrito</button>
				</div>
				{cartItems?.length === 0 ?
					(<p className='nohay'> No hay productos</p>)
					: (<>
						<div className="modal-content-cart">

							{loading ? (
								<p >Cargando...</p>
							) : (
								<div>
									{cartItems.map((item, index) => (
										<div key={`cart-item-${index}`} className='cardProductCart' >
											<Anchor to={`/producto/${item?.idProducto}/${item?.titulo?.replace(/\s+/g, '-')}`} onClick={closeModal}>
												<img src={obtenerImagen(item)} alt="imagen" />
											</Anchor>
											<div className='cardProductCartText'>
												<h3>{item.titulo}</h3>
												<span>
													{item?.item?.map((sabor, index) => (
														<span key={index}> {sabor}</span>
													))}
												</span>
												<strong>{moneda} {item?.precio}</strong>
											</div>
											<div className='deColumn'>
												<button onClick={() => removeFromCart(item.idProducto)} className='deleteCart'>  <FontAwesomeIcon icon={faTrash} /></button>
												<div className='deFlexCantidad'>
													<button onClick={() => decreaseQuantity(index)}>-</button>
													<span>{item.cantidad}</span>
													<button onClick={() => increaseQuantity(index)}>+</button>
												</div>
											</div>
										</div>
									))}
								</div>
							)}
						</div>
						<div className='deColumnCart'>
							<h4>Total: {moneda} {totalPrice.toFixed(2)}</h4>
							<div className='deFLexBtns'>
								<button className='btnPagar' onClick={openModal2}>
									Continuar
								</button>
							</div>
						</div>

						<Modal
							isOpen={modalIsOpen2}
							onRequestClose={closeModal2}
							className="modal-cart"
							overlayClassName="overlay-cart"
							ariaHideApp={false}
						>
							<div className='deFLex'>
								<button onClick={closeModal2} ><FontAwesomeIcon icon={faArrowLeft} />  </button>
								<h4>Agregar Detalles</h4>
							</div>
							<div className="modal-send-form">
								<input
									type="text"
									id="name"
									value={name}
									onChange={(e) => setName(e.target.value)}
									placeholder='Nombre (opcional)'
								/>

								<input
									type="email"
									id="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									placeholder='E-mail'
								/>


								<div className='deFLexRadio'>
									<label>Opciones de entrega</label>

									<select>
										<option
											type="radio"
											id="delivery"
											name="deliveryOption"
											value="delivery"
											checked={deliveryOption === 'delivery'}
											onChange={() => setDeliveryOption('delivery')}
										>
											Envío a domicilio
										</option>
										<option
											type="radio"
											id="pickup"
											name="deliveryOption"
											value="pickup"
											checked={deliveryOption === 'pickup'}
											onChange={() => setDeliveryOption('pickup')}
										>
											Retirar personalmente
										</option>
									</select>
								</div>

								<div className='deFLexRadio'>
									<label>Formas de pago</label>
									<select >
										<option
											type="radio"
											id="efectivo"
											name="paymentMethod"
											value="efectivo"
											checked={paymentMethod === 'efectivo'}
											onChange={() => setPaymentMethod('efectivo')}
										>
											Efectivo</option>
										<option
											type="radio"
											id="transferencia"
											name="paymentMethod"
											value="transferencia"
											checked={paymentMethod === 'transferencia'}
											onChange={() => setPaymentMethod('transferencia')}
										>
											Transferencia
										</option>
									</select>

								</div>
								<input
									type="text"
									id="codigo"
									value={codigo}
									onChange={(e) => setCodigo(e.target.value)}
									placeholder='Codigo de descuento (opcional)'
								/>
								<textarea
									placeholder="Agrega una nota (opcional)"
									value={noteText}
									onChange={(e) => setNoteText(e.target.value)}
								/>
								<div className='deColumnCart'>
									<h4>Total: {moneda} {totalPrice.toFixed(2)}</h4>
									<div className='deFLexBtns'>
										<button className='btnPagar' onClick={crearPedido}>
											Pagar
										</button>
									</div>
								</div>
							</div>
						</Modal>
					</>)}
			</Modal>
			<Modal
				isOpen={modalPedidosIsOpen}
				className="modal-cart "
				overlayClassName="overlay-cart"
				onRequestClose={closePedidosModal}
				ariaHideApp={false}
			>
				<div className='deFLex justify-end'>
					<button onClick={closePedidosModal} ><FontAwesomeIcon icon={faClose} />  </button>
				</div>
				{pedidos?.length === 0 ?
					(<p className='nohay'> No hay pedidos</p>)
					: (<>
						<div className="modal-content-cart ">
							{loading ? (
								<p >Cargando...</p>
							) : (
								<div className=''>
									<h3 className='font-bold  text-lg my-2'>En proceso</h3>
									{pedidos.map((item, index) => {
										const temporalProducts = JSON.parse(item?.productos) || []

										const sucursal = sucursales?.filter(s => s.id == Number(item.sucursal))
										if (item?.estado != 'Rechazado' && item?.estado != 'Entregado') {

											return <div key={`cart-item-${index}`} className='cardProductCart' >
												<Anchor to={`/producto/${item?.idProducto}/${item?.titulo?.replace(/\s+/g, '-')}`} onClick={closeModal}>
													<img src={temporalProducts[0]?.imagen} alt="imagen" />
												</Anchor>
												<div className='cardProductCartText'>
													<h3>Pedido #00{item.idPedido} <span>({sucursal?.[0]?.nombre})</span></h3>
													<strong>Estado: {` `}
														<strong style={{ color: item.estado == 'Rechazado' ? 'red' : item.estado == 'Entregado' ? 'green' : 'Black' }}>
															{item.estado}
														</strong>
													</strong>
													<strong>{item?.createdAt}</strong>
												</div>
												<div className='deColumn'>
													{item?.estado == 'Pendiente' &&
														<button onClick={() => removeFromCart(item.idProducto)} className='deleteCart'>  {/*<FontAwesomeIcon icon={faTrash} />*/} </button>
													}
												</div>
											</div>
										}
									})}
									{
										pedidos.filter(item => item.estado == 'Pendiente').length === 0 && 
										<h4>
											No hay pedidos en curso
										</h4>
									}
									<hr />
									 
									<h3 className='font-bold  text-lg my-2'>Historial</h3>
									{pedidos.map((item, index) => {
										const temporalProducts = JSON.parse(item?.productos) || []

										const sucursal = sucursales?.filter(s => s.id == Number(item.sucursal))
										if (item?.estado == 'Rechazado' || item?.estado == 'Entregado') {
											return <div key={`cart-item-${index}`} className='cardProductCart' >
												<Anchor to={`/producto/${item?.idProducto}/${item?.titulo?.replace(/\s+/g, '-')}`} onClick={closeModal}>
													<img src={temporalProducts[0]?.imagen} alt="imagen" />
												</Anchor>
												<div className='cardProductCartText'>
													<h3>Pedido #00{item.idPedido}  <span>({sucursal?.[0]?.nombre})</span></h3>

													<strong>{item?.createdAt}</strong>
												</div>
												<div className='deColumn'>
													<strong style={{ color: item.estado == 'Rechazado' ? 'red' : item.estado == 'Entregado' ? 'green' : 'Black' }}>
														{item.estado}
													</strong>
												</div>
											</div>
										}
									})}

								</div>
							)}
						</div>

					</>)}

			</Modal>

		</div >
	);
}

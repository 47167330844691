import React, { useEffect, useState, useRef } from 'react';
import './Footer.css'
import { Link as Anchor } from 'react-router-dom';
import logo from '../../images/logo.png'
import eu1 from '../../images/union-europea-1.png'
import eu2 from '../../images/union-europea-2.png'
import baseURL from '../url';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import { Fade, Zoom } from 'react-awesome-reveal';
import { useInView } from 'react-intersection-observer';

export default function Footer() {
    const [productos, setProductos] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [contactos, setContactos] = useState([]);
    const [ref4, inView4] = useInView({ triggerOnce: true });

    useEffect(() => {
        cargarProductos();

    }, []);
    const cargarProductos = () => {
        fetch(`${baseURL}/productosGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                const categoriasMap = new Map();
                data.productos.forEach(producto => {
                    const categoria = producto.categoria;
                    if (categoriasMap.has(categoria)) {
                        categoriasMap.get(categoria).push(producto);
                    } else {
                        categoriasMap.set(categoria, [producto]);
                    }
                });
                const categoriasArray = Array.from(categoriasMap, ([categoria, productos]) => ({ categoria, productos }));
                setCategorias(categoriasArray?.slice(0, 3));
                setProductos(data.productos?.slice(0, 3));
            })
            .catch(error => console.error('Error al cargar productos:', error));
    };

    useEffect(() => {
        cargarContacto();

    }, []);


    const cargarContacto = () => {
        fetch(`${baseURL}/contactoGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setContactos(data.contacto[0] || []);
            })
            .catch(error => console.error('Error al cargar contactos:', error));
    };


    return (
      <section ref={ref4} className="bg-chako-700">
        {inView4 && (<Zoom triggerOnce>
        <div className='flex flex-col md:flex-row justify-center  bg-chako-700  p-4 w-full gap-10 font-[400]'>
            <div className='footerText  text-2xl  flex flex-col gap-0 items-center justify-center md:justify-start'>
                <Anchor to='/'>   
                  <img  className={'md:w-44 md:h-44 '}src={logo} alt="logo" />
                </Anchor>
            </div>
            <div className='flex flex-col text-white items-start justify-start gap-1 md:w-3/12  px-4 mt-5 '>
                <span className='text-2xl'><b>Legales</b> </span>
                <Anchor  className='font-[500]' to='/accessibility' >Accesibilidad</Anchor> 
                <Anchor  className='font-[500]' to='/contract-terms' >Términos y Condiciones</Anchor>  
                <div className='flex gap-4 w-full' to='' target="_blank">   
                  <img src={eu1} alt="eu1" className='w-full h-full ' /> 
                </div> 
            </div>
            <div className='flex flex-col text-white items-start justify-start gap-1 md:w-3/12 px-4 mt-5'>
              <span className='text-2xl'><b>Teléfonos</b></span> 
                <Anchor className='font-[500]' to={`tel:912378458`} target="_blank">
                  <i className='fa fa-phone mr-2 text-lg'></i> {'Torrejón: 912 37 84 58'}
                </Anchor>
                <Anchor  className='font-[500]' to={`tel:949096591`} target="_blank">
                  <i className='fa fa-phone mr-2 text-lg'></i> {'Azuqueca: 949 09 65 91'}
                </Anchor>
                <Anchor  className='font-[500]' to={`tel:911446411`} target="_blank">
                  <i className='fa fa-phone mr-2 text-lg'></i> {'Arganda: 911 44 64 11'}
                </Anchor> 
               
                <span className='text-2xl'><b>Email</b></span>
                <Anchor className='font-[500]'  to={`mailto:${contactos.email}`} target="_blank">
                  <i className='fa fa-envelope mr-2 text-lg'></i> {contactos.email}
                </Anchor>
            </div>
            <div className='flex flex-col text-white items-start justify-start gap-1 md:w-3/12 px-4 mt-5'>
                <span><b className='text-2xl'>Dirección</b></span>
                <Anchor className='font-[500]'  to={`https://www.google.com/maps?q=${encodeURIComponent('Av. de los Fresnos, 25, 28850 Torrejón de Ardoz, Madrid')}`} target="_blank">
                <i className='fa fa-map-marker mr-2 text-lg'></i> {'Av. de los Fresnos, 25, 28850 Torrejón de Ardoz, Madrid'}
                </Anchor> 
                <span><b>Síguenos</b></span> 
                <div className='text-4xl  flex w-full justify-center items-center'>
                  <div className='flex  gap-4'>
                    <Anchor to={contactos.instagram} target="_blank">
                      <i className='fa fa-instagram'></i>
                    </Anchor> 
                    <Anchor to={contactos.facebook} target="_blank">
                      <i className='fa fa-facebook'></i>
                    </Anchor>
                    <Anchor to={contactos.facebook} target="_blank">
                      <i className='fa fa-tripadvisor'></i>
                    </Anchor>
                  </div>
                </div>
            </div>
        </div>
        </Zoom>)}
        </section>
    )
}

/*

import React from "react";
import logo3 from "../../img/18.png";


const Footer = () => {
  return (
    <footer className="mt-5">
      <div className="grid md:grid-cols-3 md:grid-rows-2 grid-cols-1 grid-rows-4 gap-0 text-chako-700">
        <div className="flex  items-center justify-center md:col-start-1 md:col-end-2 md:row-start-1 md:row-end-3 col-start-1 col-end-2 row-start-1 row-end-2">
          <img  src={logo3} alt=""/>
        </div>
        <div className="flex flex-col justify-center md:col-start-2 md:col-end-3 md:row-start-1 md:row-end-2 col-start-1 col-end-2 row-start-2 row-end-3 text-center">
          <h2 className="font-black text-3xl">LEGALES</h2>
          <p>Accesibilidad</p>
          <p>Términos y Condiciones</p>
          <h2 className="font-black text-3xl pt-7">DIRECCIÓN</h2>
          <p>Av. de los Fresnos, 25, 28850 Torrejón de Ardoz, Madrid</p>
        </div>
        <div className="flex flex-col justify-center md:col-start-3 md:col-end-4 md:row-start-1 md:row-end-2 col-start-1 col-end-2 row-start-3 row-end-4 text-center">
          <h2 className="font-black text-3xl">TELÉFONOS</h2>
          <p>Torrejón: 912 37 84 58</p>
          <p>Azuqueca: 949 09 65 91</p>
          <p>Arganda: 911 44 64 11</p>
          <h2 className="font-black text-3xl pt-7">EMAIL</h2>
          <p>bussiness.chako@gmail.com</p>
        </div>
        <div className="flex flex-col justify-center md:col-start-2 md:col-end-4 md:row-start-2 md:row-end-3 col-start-1 col-end-2 row-start-4 row-end-5">
          <h2 className="text-center mt-30 font-black text-3xl">SÍGUENOS</h2>
          <div className="flex justify-center items-center mt-5">
            <svg fill="#e40613" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="50" viewBox="0 0 30 30">
              <path d="M12,27V15H8v-4h4V8.852C12,4.785,13.981,3,17.361,3c1.619,0,2.475,0.12,2.88,0.175V7h-2.305C16.501,7,16,7.757,16,9.291V11 h4.205l-0.571,4H16v12H12z"></path>
            </svg>
            <svg fill="#e40613" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="50" viewBox="0 0 50 50">
              <path d="M 16 3 C 8.8324839 3 3 8.8324839 3 16 L 3 34 C 3 41.167516 8.8324839 47 16 47 L 34 47 C 41.167516 47 47 41.167516 47 34 L 47 16 C 47 8.8324839 41.167516 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.086484 5 45 9.9135161 45 16 L 45 34 C 45 40.086484 40.086484 45 34 45 L 16 45 C 9.9135161 45 5 40.086484 5 34 L 5 16 C 5 9.9135161 9.9135161 5 16 5 z M 37 11 A 2 2 0 0 0 35 13 A 2 2 0 0 0 37 15 A 2 2 0 0 0 39 13 A 2 2 0 0 0 37 11 z M 25 14 C 18.936712 14 14 18.936712 14 25 C 14 31.063288 18.936712 36 25 36 C 31.063288 36 36 31.063288 36 25 C 36 18.936712 31.063288 14 25 14 z M 25 16 C 29.982407 16 34 20.017593 34 25 C 34 29.982407 29.982407 34 25 34 C 20.017593 34 16 29.982407 16 25 C 16 20.017593 20.017593 16 25 16 z"></path>
            </svg>
            <svg fill="#e40613" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="150" height="50" viewBox="0 0 50 50">
              <path d="M 25 11 C 19.167969 11 13.84375 12.511719 9.789063 15 L 2 15 C 2 15 3.753906 17.152344 4.578125 19.578125 C 2.96875 21.621094 2 24.195313 2 27 C 2 33.628906 7.371094 39 14 39 C 17.496094 39 20.636719 37.492188 22.828125 35.105469 L 25 38 L 27.171875 35.105469 C 29.363281 37.492188 32.503906 39 36 39 C 42.628906 39 48 33.628906 48 27 C 48 24.195313 47.03125 21.621094 45.421875 19.578125 C 46.246094 17.152344 48 15 48 15 L 40.203125 15 C 36.148438 12.511719 30.828125 11 25 11 Z M 25 13 C 28.882813 13 32.585938 13.707031 35.800781 15.011719 C 30.964844 15.089844 26.824219 18.027344 25 22.214844 C 23.171875 18.019531 19.023438 15.078125 14.171875 15.011719 L 14.242188 14.988281 C 17.453125 13.699219 21.144531 13 25 13 Z M 14 17 C 19.523438 17 24 21.476563 24 27 C 24 32.523438 19.523438 37 14 37 C 8.476563 37 4 32.523438 4 27 C 4 21.476563 8.476563 17 14 17 Z M 36 17 C 41.523438 17 46 21.476563 46 27 C 46 32.523438 41.523438 37 36 37 C 30.476563 37 26 32.523438 26 27 C 26 21.476563 30.476563 17 36 17 Z M 14 21 C 10.6875 21 8 23.6875 8 27 C 8 30.3125 10.6875 33 14 33 C 17.3125 33 20 30.3125 20 27 C 20 23.6875 17.3125 21 14 21 Z M 36 21 C 32.6875 21 30 23.6875 30 27 C 30 30.3125 32.6875 33 36 33 C 39.3125 33 42 30.3125 42 27 C 42 23.6875 39.3125 21 36 21 Z M 14 23 C 16.210938 23 18 24.789063 18 27 C 18 29.210938 16.210938 31 14 31 C 11.789063 31 10 29.210938 10 27 C 10 24.789063 11.789063 23 14 23 Z M 36 23 C 38.210938 23 40 24.789063 40 27 C 40 29.210938 38.210938 31 36 31 C 33.789063 31 32 29.210938 32 27 C 32 24.789063 33.789063 23 36 23 Z"></path>
            </svg>
          </div>
        </div>
      </div>
    </footer>
  );
}

*/
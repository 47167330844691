
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import "./Reservas.css"
import Cookies from 'js-cookie';
import baseURL from "../../Components/url";

const getAvailableTimes = (date) => {

  
  const openingTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 13, 0, 0, 0);
  const closingTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 15, 0, 0);


  const times = [];
  const currentTime = new Date();

  if (date.toDateString() === currentTime.toDateString()) {
    if (currentTime.getHours() > 23 || (currentTime.getHours() === 23 && currentTime.getMinutes() > 15)) {
      return times;
    }
    openingTime.setTime(Math.max(openingTime.getTime(), currentTime.getTime() + 15 * 60 * 1000));
  }

  while (openingTime <= closingTime) {
    times.push(openingTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
    openingTime.setMinutes(openingTime.getMinutes() + 15);
  }

  return times;
};
 

const Reservas = () => {
  const [availableTimes, setAvailableTimes] = useState([]);
  const [step, setStep] = useState(1);
  const [sucursales, setSucursales] = useState([])
  const [idRestaurate, setIdRestaurante] = useState(() => Cookies.get('idRestaurate') || 0);
  const [selectedSucursal, setSelctedSucursal] = useState(idRestaurate)
  const today = new Date();
  const minDate = today.toISOString().split('T')[0];


  const obtenerSucursales = (id = 1) => {
    fetch(`${baseURL}/sucursalesGet.php`, {
      method: 'GET',
    })
      .then(response => response.json())
      .then(data => {
        const sucursalesAll = data.sucursales

        if (idRestaurate > 0) {
          setSelctedSucursal(sucursalesAll.find(restaurante => restaurante.id == Number(idRestaurate)))

        } else {

        }
        setSucursales(sucursalesAll);
      })
      .catch(error => console.error('Error al cargar sucursales:', error));
  };


  const handleNextStep = () => {
    setStep(2);
  };

  const handlePrevStep = () => {
    setStep(1);
  };
  useEffect(() => {
    obtenerSucursales(idRestaurate)
  })

  return (
    <>
      <Navbar />
      <section>

        <div className="mt-16 mb-16 px-8 ">
          <div className="container mx-auto w-[95%] h-[20%]">
            <div className="relative pt-10 text-center font-Montserrat font-black text-white">
              <h2 className="block text-3xl lg:text-4xl text-[#5c5959]">RESERVA AHORA</h2>
            </div>
            <div className="py-12 flex justify-center border border-white bg-white ">



              <div className="w-3/4 text-xs justify-center  items-center  ">
                <div className="flex justify-center items-center">

                  {step === 1 ? (
                    <Formik
                      initialValues={{
                        date: '',
                        people: '',
                        location: '',
                        time: ''
                      }}
                      validationSchema={Yup.object({
                        date: Yup.date().required('Ingrese la fecha de su reserva'),
                        people: Yup.number().min(1, 'Minimo una persona').required('Ingrese la cantidad de personas'),
                        location: Yup.string().required('ingrse donde desea resevar'),
                        time: Yup.string().required('Ingrese la hora de la reserva')
                      })}
                      onSubmit={(values, { resetForm }) => {
                        console.log(values);
                        handleNextStep();
                      }}
                    >
                      {({ values, setFieldValue, resetForm }) => (
                        <Form
                          className=" w-full md:w-[60%]" >
                          <div className=" my-2  ">
                            <div className="flex flex-col gap-1 w-full" >
                              <label htmlFor="date" className="font-bold text-sm  text-[#5c5959]">Fecha</label>
                              <Field
                                className="shadow-sm rounded-md  px-3 py-2  border-gray-300  border-[1px] "
                                type="date"
                                name="date"
                                min={minDate}
                                onChange={(e) => {
                                  setFieldValue('date', e.target.value);
                                  const selectedDate = new Date(e.target.value);
                                  setAvailableTimes(getAvailableTimes(selectedDate));
                                }}
                              />
                              <ErrorMessage
                                className="mensaje"
                                name="date"
                                component="div" />
                            </div>
                          </div>

                          <div className="my-5">
                            <label htmlFor="people" className="font-bold text-sm  text-[#5c5959]">Numero de Personas</label>
                            <div className="shadow-sm rounded-md w-full   border-[1px] border-gray-300 mt-1">
                              <Field as="select" name="people" className="py-1 px-3 border-[1px] my-1">
                                <option value="">Select</option>
                                {Array.from({ length: 20 }, (_, i) => i + 1).map((num) => (
                                  <option key={num} value={num}>{num}</option>
                                ))}
                              </Field>
                            </div>
                            <ErrorMessage
                              className="mensaje"
                              name="people"
                              component="div" />
                          </div>

                          <div className="my-5">
                            <label htmlFor="location" className="font-bold text-sm  text-[#5c5959]">Locacion</label>
                            <div className="shadow-sm rounded-md w-full border-[1px] border-gray-300 mt-1">
                              <Field as="select" name="location"  className="py-1 px-3 border-[1px] my-1" value={idRestaurate} onChange={()=>setIdRestaurante}>
                                <option value="0">Select</option>
                                { sucursales.map((item, index) => (
                                    item.mesas == `1` ?
                                     <option value={item.id} key={`key-item-${index}`}>{item.nombre}</option>:
                                     <></>
                                  ))}
                              </Field>
                            </div>
                            <ErrorMessage
                              className="mensaje"
                              name="location"
                              component="div"
                            />
                          </div>
                          <div className="my-5">
                            <label htmlFor="time" className="font-bold text-sm  text-[#5c5959]">Hora</label>
                            <div className="shadow-sm rounded-md w-full border-[1px] border-gray-300 mt-1">
                              <Field as="select" name="time" className="py-1 px-3 border-[1px] my-1">
                                <option value="">Select</option>
                                {availableTimes.map((time, index) => (
                                  <option key={index} value={time}>
                                    {time}
                                  </option>
                                ))}
                              </Field>
                            </div>
                            <ErrorMessage
                              className="mensaje"
                              name="time"
                              component="div" />
                          </div>
                          <div className="flex flex-col md:flex-row gap-0 md:gap-2">
                            <button type="button" onClick={resetForm} className="w-full px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-chako-700 hover:bg-chako-950 hover:shadow-lg ">Limpiar</button>
                            <button type="submit" className="w-full px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-chako-700 hover:bg-chako-950 hover:shadow-lg ">Siguiente</button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    <Formik
                      initialValues={{
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        comments: '',
                        acceptTerms: false,
                        acceptMarketing: false,
                        acceptCommunications: false,
                      }}
                      validationSchema={Yup.object({
                        firstName: Yup.string().required('Ingrese su nombre'),
                        lastName: Yup.string().required('ingrese su apellido'),
                        email: Yup.string().email('Ingrese una direccion de correo valida').required('Ingrese su email'),
                        phone: Yup.string().required('Ingrese telefono de contacto'),
                        comments: Yup.string(),
                        acceptTerms: Yup.bool().oneOf([true], 'Acepta los termminos y condiciones'),
                      })}
                      onSubmit={(values) => {
                        console.log(values);
                      }}
                    >
                      {({ resetForm }) => (

                        <Form>

                          <div className="flex flex-col">



                            <div className="flex flex-col">
                              <label htmlFor="firstName" className="font-bold text-sm  text-[#5c5959]">Nombre</label>
                              <Field type="text" name="firstName" className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 mt-1" />
                              <ErrorMessage name="firstName" component="div" className="mensaje" />
                            </div>

                            <div className="flex flex-col">
                              <label htmlFor="lastName" className="font-bold text-sm  text-[#5c5959]">Apellido</label>
                              <Field type="text" name="lastName" className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 mt-1" />
                              <ErrorMessage name="lastName" component="div" className="mensaje" />
                            </div>

                            <div className=" flex flex-col my-1">
                              <label htmlFor="email" className="font-bold text-sm  text-[#5c5959]">Email</label>
                              <Field type="email" name="email" className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 mt-1" />
                              <ErrorMessage name="email" component="div" className="mensaje" />
                            </div>

                            <div className="flex flex-col my-1">
                              <label htmlFor="phone" className="font-bold text-sm  text-[#5c5959]">Telefono</label>
                              <Field type="text" name="phone" className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 mt-1" />
                              <ErrorMessage name="phone" component="div" className="mensaje" />
                            </div>

                            <div className=" flex flex-col mt-1 mb-3">
                              <label htmlFor="comments" className="font-bold text-sm  text-[#5c5959]">Comentario</label>
                              <div className="shadow-sm rounded-md w-full border-[1px] border-gray-300 mt-1">
                                <Field as="textarea" name="comments" className=" mt-1" />
                              </div>
                              <ErrorMessage name="comments" component="div" className="mensaje" />
                            </div>

                            <div className="flex flex-col font-light gap-1 ">
                              <label className=" text-sm  text-[#5c5959]">
                                <Field type="checkbox" name="acceptTerms" className="h-4 w-4 rounded border-gray-300 mr-2" />
                                Acepto las condiciones de uso y privacidad
                              </label>
                              <ErrorMessage name="acceptTerms" component="div" className="mensaje" />

                              <label className="text-sm text-[#5c5959]">
                                <Field type="checkbox" name="acceptMarketing" className="h-4 w-4 rounded border-gray-300 mr-2" />
                                Acepto el uso de mis datos por parte del restaurante para fines comerciales
                              </label>

                              <label className="text-sm  text-[#5c5959]">
                                <Field type="checkbox" name="acceptCommunications" className="h-4 w-4 rounded border-gray-300 mr-2" />
                                Acepto recibir comunicaciones del restaurante para fines comerciales
                              </label>
                            </div>

                            <div className="flex flex-col md:flex-row gap-0 md:gap-2">
                              <button type="button" onClick={handlePrevStep} className="w-full px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-chako-700 hover:bg-chako-950 hover:shadow-lg ">Cancelar reserva</button>
                              <button type="submit" className="w-full px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-chako-700 hover:bg-chako-950 hover:shadow-lg ">Enviar</button>
                            </div>

                          </div>
                        </Form>
                      )}
                    </Formik>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Reservas;
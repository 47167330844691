import React, { useEffect, useState, useRef } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import baseURL from '../url';
import './Banners.css';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import res from '../../images/backgraund-1.jpg'
SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function Banners() {
	const [images, setImages] = useState([]);
	const [loading, setLoading] = useState(true);
	const swiperRef = useRef(null);


	useEffect(() => {
		cargarBanners();
	}, []);

	useEffect(() => {
		if (swiperRef.current) {
			swiperRef.current?.update();
		}
	}, [images]);

	const cargarBanners = () => {
		fetch(`${baseURL}/bannersGet.php`, {
			method: 'GET',
		})
			.then(response => response.json())
			.then(data => {
				const bannerImages = data.banner.map(banner => banner.imagen);
				setImages(bannerImages);
				setLoading(false);
			})
			.catch(error => {
				console.error('Error   al cargar productos:', error)

			});
	};

	return (
		<section className=''>
			{loading ? (
				<div className='w-full h-24 bg-chako-800 rounded-3xl'>

				</div>
			) : (
				<div className="relative w-full h-[200px]">
					<img
						className="absolute h-full w-full object-cover object-center"
						src={res}
						alt="nature"
					/>
					<div className="absolute inset-0 h-full w-full bg-chako-700/25"></div>
					<div className="relative pt-28 text-center font-Montserrat font-black text-white">
						<h2 className="block text-3xl lg:text-4xl"></h2>
					</div>
				</div>
			)}
		</section>
	);
}
